module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"en","locales":"en fr","configPath":"/builds/GuilhemBarboux/guixdev/themes/gatsby-theme-guix-core/src/i18n.json"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Guilhem Barboux","short_name":"Guix","description":"Hi ! My name is Guilhem Barboux. I am a french developper specialized in webdesign, 3D projects and video games","lang":"en","start_url":"/","theme_color":"#1b1b1b","background_color":"#fff","display":"standalone","icon":"src/assets/favicon.png","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a47708b0f129e25bdea0133a9a4d5994"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-107210978-1"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0}},
    },{
      plugin: require('../../../themes/gatsby-theme-guix/gatsby-browser.js'),
      options: {"plugins":[],"manifest":{"name":"Guilhem Barboux","short_name":"Guix","description":"Hi ! My name is Guilhem Barboux. I am a french developper specialized in webdesign, 3D projects and video games","lang":"en","start_url":"/","theme_color":"#1b1b1b","background_color":"#fff","display":"standalone","icon":"src/assets/favicon.png","icon_options":{"purpose":"any maskable"}}},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
