import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from "react"

export function createLazyContext<A extends {} | null>() {
  type LazyType = A & {
    setLazy: (l: A) => void
  }

  const ctx = createContext<LazyType | undefined>(undefined)

  function useCtx() {
    const c = useContext(ctx)
    if (c === undefined) {
      throw new Error("useCtx must be inside a Provider with a value")
    }
    return c
  }

  function Provider(props: PropsWithChildren<{}>) {
    const [state, setState] = useState<A>({} as A)

    return (
      <ctx.Provider
        value={{
          ...state,
          setLazy: setState,
        }}
        {...props}
      />
    )
  }

  return [useCtx, Provider] as const
}

export default createLazyContext
