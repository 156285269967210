import React from "react"
import WrapRoot from "./src/WrapRoot"
import WrapPage from "./src/WrapPage"

export const wrapRootElement = ({ element, props }) => {
  return <WrapRoot {...props}>{element}</WrapRoot>
}

export const wrapPageElement = ({ element, props }) => {
  return <WrapPage {...props}>{element}</WrapPage>
}

export const onClientEntry = () => {
  // Polyfills
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
  }
  /*if (typeof Worker === "undefined") {
    import("pseudo-worker/polyfill")
  }*/
  // Hack scroll
  if ("scrollRestoration" in history) {
    history.scrollRestoration = "manual"
  }
}

export const shouldUpdateScroll = () => {
  return false
}
