import { keyframes } from "@emotion/react"

const wave = keyframes({
  from: { opacity: 0.35, transform: "translateY(0px)" },
  "20%": { opacity: 1, transform: "translateY(2px)" },
  "40%": { opacity: 0.35, transform: "translateY(0px)" },
  to: { opacity: 0.35 },
})

const theme = {
  breakpoints: [48, 64, 96, 120].map(n => n + "em"),
  space: [
    0,
    "20px",
    "36px",
    "62px",
    "240px",
    "120px",
    "75px",
    "300px",
    "180px",
  ],
  colors: {
    background: "#1B1B1B",
    text: "#ffffff",
    navbar: "#888888",
    videogame: "#ff72f4",
    project3D: "#27cfd5",
    webdesign: "#725fff",
  },
  fonts: {
    body: "galano",
    heading: "galano",
    link: "worksans",
  },
  lineHeights: {
    body: 1.4,
    jumbotron: 1.55,
    heading: 1,
    link: 1,
  },
  zIndices: {
    header: 999,
    loading: 9999,
  },
  fontSizes: [
    ".875rem", // Text s
    "1.25rem", // Text l
    "1.5rem", // Text xl

    "1.125rem", // Title s
    "1.375rem", // Title ml
    "1.625rem", // Title xl

    "1.125rem", // Jumbotron s
    "1.5rem", // Jumbotron ml
    "2.25rem", // Jumbotron xl

    ".75rem", // Link s
    ".875rem", // Link mxl

    ".75rem", // Category sm
    ".875rem", // Category lxl

    "1.125rem", // Title mobile
    "1.5rem", // Title
    ".875rem", // Category
    ".75rem", // category sm
    "1rem", // Contact
    "14px", // Link
  ],
  fontWeights: {
    body: 400,
    heading: 700,
    jumbotron: 400,
    link: 300,
  },
  borders: {},
  sizes: {
    content: "1440px",
    project: "720px",
    scrollbar: "8px",
  },
  text: {
    heading: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      textTransform: "uppercase",
    },
    paragraph: {
      fontSize: [0, "inherit", 1, 2],
      letterSpacing: ".5px",
      pb: [1, 1, 2],
    },
    h1: {
      variant: "text.heading",
      fontSize: [3, 4, 4, 5],
      pb: [1, 1, 2],
    },
    h2: {
      fontFamily: "heading",
      fontWeight: "jumbotron",
      lineHeight: "jumbotron",
      letterSpacing: ".5px",
      fontSize: [6, 7, 7, 8],
      pb: [1, 1, 2],
    },
    h4: {
      variant: "text.heading",
      fontSize: [11, 11, 12],
    },
    link: {
      fontSize: 9,
      color: "inherit",
      fontFamily: "link",
      lineHeight: "link",
      fontWeight: "link",
      letterSpacing: "1px",
      textDecoration: "inherit",
      textTransform: "uppercase",
    },
    jumbotron: {
      variant: "text.h2",
      py: 0,
    },
    contact: {
      variant: "text.h2",
      fontSize: ["inherit", "inherit", 1, 2],
    },
    category: {
      variant: "text.h4",
    },
    explain: {
      variant: "text.link",
    },
    highlight: {
      whiteSpace: "nowrap",
      fontWeight: "bold",
      color: "inherit",
      textDecoration: "inherit",
    },
  },
  images: {
    icon: {
      display: "inline-block",
      verticalAlign: "middle",
      height: "1em",
      "& + *": {
        verticalAlign: "middle",
      },
      svg: {
        display: "inline-block",
        height: "100%",
        verticalAlign: "top",
      },
    },
    "icon-small": {
      variant: "images.icon",
      height: "7px",
    },
    "icon-small-color": {
      variant: "images.icon-small",
      "svg, svg *": {
        fill: "inherit",
        stroke: "inherit",
      },
    },
    jumbotron: {
      variant: "images.icon",
    },
    explain: {
      variant: "images.icon",
      height: "8px",
      opacity: 0.35,
      "&.active": {
        animationDuration: "1.6s",
        animationIterationCount: "infinite",
        animationTimingFunction: "ease-out",
        animationName: `${wave}`,
      },
      "svg *": {
        stroke: "text",
      },
    },
  },
  links: {
    nav: {
      variant: "text.link",
      display: "inline-block",
      p: 1,
      cursor: "pointer",
    },
    menu: {
      variant: "links.nav",
      "> span": {
        display: "inline-block",
        position: "relative",
        overflow: "hidden",
        pb: "4px",
        "&:before, &:after": {
          display: "block",
          content: "''",
          position: "absolute",
          height: "1px",
          background: "text",
          bottom: 0,
          left: 0,
          width: "100%",
        },
        "&:before": {
          transition: "transform 0ms",
          transform: "translateX(-100%)",
          transitionDelay: 0,
        },
        "&:after": {
          transition: "transform 120ms ease-out",
          transform: "translateX(100%)",
          transitionDelay: 0,
        },
        "&:hover": {
          "&:before": {
            transition: "transform 120ms ease-out",
            transform: "translateX(0)",
          },
          "&:after": {
            transition: "transform 0ms",
            transitionDelay: "120ms",
            transform: "translateX(0)",
          },
        },
      },
    },
    locale: {
      variant: "links.nav",
      "&:hover span, &.active span": {
        borderBottom: "1px solid white",
        paddingBottom: "4px",
      },
    },
    socials: {
      variant: "links.nav",
    },
    highlight: {
      variant: "text.highlight",
      cursor: "pointer",
    },
  },
  buttons: {},
  layout: {
    container: {
      maxWidth: "content",
      px: [0, 1, "60px", "100px"],
    },
    header: {
      position: ["absolute", "absolute", "fixed"],
      left: 0,
      right: 0,

      pt: ["10px", "20px"],
      px: [0, 0, "40px", "80px"],
      maxWidth: "content",

      zIndex: "header",
    },
    footer: {
      maxWidth: "content",
      px: [1, 1, "60px", "100px"],
      pb: [5, 7, 5],
    },
  },
  cards: {
    project: {
      maxWidth: "project",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      rowGap: [1, 1, 2],
      py: [1, 1, 2],
      header: {
        pl: [1, 1, 3],
        whiteSpace: "nowrap",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        columnGap: ["10px", 2],
      },
      h2: {
        variant: "text.h1",
        pb: 0,
      },
      h3: {
        variant: "text.h4",
        pb: 0,
      },
      p: {
        m: 0,
        px: [1, 1, 3],
        pb: 0,
      },
    },
  },
  variants: {
    loading: {
      position: "fixed",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,

      zIndex: "loading",
      opacity: 1,

      background: "background",

      pointerEvents: "none",
      ".loading-bar": {
        background: "text",
        height: "scrollbar",
      },
    },
  },
  styles: {
    root: {
      // uses the theme values provided above
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      height: "100%",
      // fontSize: ["87.5%", "100%", "125%", "150%"],
      // scrollBehavior: "smooth",
      transition: "opacity 300ms ease-in-out",
      body: {
        position: "relative",
        height: "100%",
        ml: "calc(100vw - 100%)",
        overflowX: "hidden",
        "&.is-loading": {
          overflowY: "hidden",
        },
        "#___gatsby": {
          height: "100%",
        },
        "#gatsby-focus-wrapper": {
          height: "100%",
        },
      },
      "*::-moz-selection": {
        color: "inherit",
        background: "inherit",
      },
      "*::selection": {
        color: "inherit",
        background: "inherit",
      },
      "*::-webkit-scrollbar": {
        width: "scrollbar",
        background: "background",
      },
      "*::-webkit-scrollbar-thumb": {
        background: "text",
      },
      ".gatsby-image-wrapper [data-main-image]": {
        opacity: "1 !important",
        transition: "none !important",
      },
      "&.no-js": {
        ".gatsby-image-wrapper > picture": {
          display: "none",
        },
        "#loading": {
          display: "none",
        },
        "#cursor": {
          display: "none",
        },
        "body.is-loading": {
          overflow: "auto !important",
        },
        ".category-image": {
          "&.webdesign": {
            display: "none",
          },
          "&.project3D": {
            display: "none",
          },
        },
        ".project-poster": {
          display: "none",
        },
      },
    },
    h1: {
      variant: "text.h1",
    },
    h2: {
      variant: "text.h2",
    },
    h4: {
      variant: "text.h4",
    },
    a: {
      variant: "text.link",
    },
    p: {
      variant: "text.paragraph",
    },
    hr: {},
  },
}

export default theme
