import React, { FunctionComponent, useEffect, useState } from "react"
import { Box } from "theme-ui"
import createLoadingContext from "../contexts/createLoadingContext"

// Context
export const [useLoading, LoadingProvider] = createLoadingContext()

// Component
interface OwnProps {
  finish: () => void
}

type Props = OwnProps

const Loading: FunctionComponent<Props> = props => {
  const { loading, loaded, percent } = useLoading()
  const [unmount, setUnmount] = useState(false)
  const [isActive, setIsActive] = useState(true)

  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        setIsActive(false)
        setTimeout(() => {
          setUnmount(true)
          props.finish()
        }, 750)
      }, 950)
    }
  }, [loading, loaded, setIsActive, setUnmount, props.finish])

  return !unmount ? (
    <Box
      id="loading"
      variant="loading"
      className={isActive ? "is-active" : "is-finish"}
      sx={{
        opacity: 1,
        "&.is-finish": {
          opacity: 0,
          transition: "opacity 400ms",
          transitionDelay: "200ms",
        },
      }}
    >
      <Box
        className="loading-bar"
        sx={{
          width: `${percent * 100}%`,
          transition: "width 600ms linear, transform 200ms ease-out",
          ".is-finish &": {
            transform: "translateY(-100%)",
          },
        }}
      />
    </Box>
  ) : null
}

export default Loading
