import React, { FunctionComponent, useEffect, useState } from "react"
import { PageProps } from "gatsby"
import createPageContext from "./contexts/createPageContext"
import Loading, { useLoading } from "./components/Loading"
import Cursor from "./components/Cursor"
import { useLibsContext } from "./WrapRoot"
import { Helmet } from "react-helmet"
import ComingSoon from "./components/ComingSoon"

// Page
export const [usePageContext, PageProvider] = createPageContext<{
  ready: boolean
  firstVisit: boolean
}>()

// Wrap-page
interface OwnProps {}

type Props = PageProps<OwnProps, { originalPath: string; locale: string }>

const WrapPage: FunctionComponent<Props> = props => {
  const [ready, setReady] = useState(false)
  const [firstVisit, setFirstVisit] = useState(true)
  const { setLazy } = useLibsContext()
  const { load } = useLoading()

  useEffect(() => {
    const fontfaceobserver = import(
      /* webpackChunkName: "fontfaceobserver" */
      /* webpackMode: "lazy-once" */
      /* webpackPreload: true */
      "fontfaceobserver/fontfaceobserver.standalone" as "fontfaceobserver"
    )

    const animejs = import(
      /* webpackChunkName: "animejs" */
      /* webpackMode: "lazy-once" */
      /* webpackPreload: true */
      "animejs/lib/anime.es.js" as "animejs"
    )

    const fonts = [
      {
        name: "worksans",
        options: {
          weight: "normal",
        },
      },
      {
        name: "galano",
        options: {
          weight: "normal",
        },
      },
      {
        name: "galano",
        options: {
          weight: 700,
        },
      },
    ].map(font =>
      fontfaceobserver.then(Observer => {
        const observer = new Observer.default(font.name, font.options)
        return observer.load()
      })
    )

    Promise.all([animejs, fontfaceobserver]).then(r =>
      setLazy({
        anime: r[0].default,
        fontfaceobserver: r[0].result,
      })
    )

    if (`localStorage` in window) {
      if (localStorage.getItem("guix") === "true") {
        setFirstVisit(false)
      }
      localStorage.setItem("guix", "true")
    }

    load([fontfaceobserver, animejs, ...fonts])
  }, [setLazy, setFirstVisit, load])

  useEffect(() => {
    /* setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight * 2,
      })
    }, 100)*/
  }, [props.location.pathname])

  return (
    <PageProvider
      value={{
        ready,
        firstVisit,
      }}
    >
      <Helmet
        bodyAttributes={{
          class: [ready ? "" : "is-loading"].join(" "),
        }}
      />
      <Loading finish={() => setReady(true)} />
      {/*<ComingSoon />*/}
      {props.children}
      <Cursor />
    </PageProvider>
  )
}

export default WrapPage
