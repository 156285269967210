import React, { FC, useEffect, useMemo, useRef, useState } from "react"
import { Box, BoxProps } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"
import createCursorContext from "../contexts/createCursorContext"
import { useLibsContext } from "../WrapRoot"

// Context
interface CursorProps {
  color: string
}

export const [useCursor, CursorProvider, useCursorHover] =
  createCursorContext<CursorProps>()

// Component
interface OwnProps {}

type Props = BoxProps & OwnProps

const Cursor: FC<Props> = props => {
  const { anime } = useLibsContext()
  const { position, options } = useCursor()

  const cursorRef = useRef<HTMLDivElement>(null!)
  const cursorEnterAnimation = useRef<any>(null)
  const cursorExitAnimation = useRef<any>(null)

  const [color, setColor] = useState("")

  useEffect(() => {
    if (!anime) return

    cursorEnterAnimation.current = anime
      .timeline({
        autoplay: false,
        duration: 320,
        easing: "easeOutBack",
      })
      .add({
        targets: cursorRef.current.querySelector("div"),
        opacity: 1,
        translateX: ["-54%", "-54%"],
        translateY: ["-54%", "-54%"],
        scale: [0, 1],
      })
      .add(
        {
          targets: cursorRef.current.querySelector("img"),
          scale: [0, 1],
        },
        200
      )

    cursorExitAnimation.current = anime
      .timeline({
        duration: 320,
        easing: "easeInExpo",
      })
      .add({
        targets: cursorRef.current.querySelector("div"),
        opacity: 0.5,
        scale: [1, 0],
      })
  }, [anime])

  useEffect(() => {
    if (options !== null) setColor(options.color)
  }, [options])

  useEffect(() => {
    if (!cursorEnterAnimation.current) return

    if (options !== null) {
      cursorExitAnimation.current.pause()
      cursorEnterAnimation.current.restart()
    } else {
      cursorEnterAnimation.current.pause()
      cursorExitAnimation.current.restart()
    }
  }, [options])

  useEffect(() => {
    if (!anime || !cursorRef.current) return

    anime({
      targets: cursorRef.current,
      translateX: position[0],
      translateY: position[1],
      duration: 0,
    })
  }, [anime, position])

  const cursorImage = useStaticQuery(graphql`
    query Cursor {
      file(name: { eq: "souris-white" }) {
        publicURL
      }
    }
  `)

  return (
    <Box
      id="cursor"
      ref={cursorRef}
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: "1000",
        pointerEvents: "none",
      }}
    >
      <Box
        sx={{
          background: color,
          transition: "background 120ms ease-in",
          borderRadius: "50%",
          p: "28px",
          transformOrigin: "center center",
          ...props.sx,
        }}
        {...props}
      >
        <img
          style={{
            width: "36px",
            display: "block",
            paddingTop: ".1rem",
            paddingLeft: ".1rem",
          }}
          src={cursorImage.file.publicURL}
          alt="cursor"
        />
      </Box>
    </Box>
  )
}

export default Cursor
