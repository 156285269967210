import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from "react"

function createLoadingContext<A extends {} | null>() {
  const ctx = createContext<{
    loading: boolean
    loaded: boolean
    percent: number
    load: (promises: Promise<void>[]) => Promise<void>
  }>({
    loading: false,
    loaded: false,
    percent: 0,
    load: ([]) => Promise.resolve(),
  })

  function useCtx() {
    const c = useContext(ctx)
    if (c === undefined)
      throw new Error("useCtx must be inside a Provider with a value")
    return c
  }

  function Provider(props: PropsWithChildren<{ loading?: boolean }>) {
    const { loading: defaultLoading, ...rest } = props

    const [loaded, setLoaded] = useState(false)
    const [loading, setLoading] = useState<boolean>(!!defaultLoading)
    const [percent, setPercent] = useState<number>(0)

    const load = useCallback(
      (promises: Promise<void>[]) => {
        setLoading(true)

        promises.map(p =>
          p.then(r => {
            setPercent(value => (value * promises.length + 1) / promises.length)
            return r
          })
        )

        return Promise.all(promises).then(() => {
          setLoaded(true)
          setLoading(false)
        })
      },
      [setLoading, setPercent, setLoaded]
    )

    return <ctx.Provider value={{ loading, loaded, percent, load }} {...rest} />
  }

  return [useCtx, Provider] as const // 'as const' makes TypeScript infer a tuple
}

export default createLoadingContext
