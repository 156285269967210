import React, {
  createContext,
  createRef,
  FC,
  PropsWithChildren,
  RefObject,
  useCallback,
  useContext,
  useState,
} from "react"

import {
  createUseGesture,
  hoverAction,
  moveAction,
  scrollAction,
  dragAction,
  useHover,
  useMove,
  UserHoverConfig,
} from "@use-gesture/react"

const createCursorContext = function <A extends {} | null>() {
  const isMouseSupport =
    typeof window !== "undefined" ? matchMedia("(pointer:fine)").matches : false
  const CursorContext = createContext<{
    position: [number, number]
    angle: number
    options: A | null
    enableCursor: (options: A) => void
    disableCursor: () => void
  }>({
    position: [0, 0],
    angle: 0,
    options: null,
    enableCursor: () => {},
    disableCursor: () => {},
  })

  const useCursor = () => {
    const c = useContext(CursorContext)
    if (c === undefined)
      throw new Error("useCursor must be inside a Provider with a value")
    return c
  }

  const useCursorHover = <RefType extends HTMLElement>(
    options: A,
    config?: UserHoverConfig
  ): [RefObject<RefType>, boolean] => {
    const hoverRef = createRef<RefType>()
    const [hovering, setHovering] = useState<boolean>(false)
    const { enableCursor, disableCursor } = useCursor()

    if (isMouseSupport) {
      useMove(
        () => {
          if (!hovering) {
            setHovering(true)
            enableCursor(options)
          }
        },
        {
          ...config,
          target: hoverRef,
        }
      )

      useHover(
        state => {
          setHovering(state.hovering || false)
          state.hovering ? enableCursor(options) : disableCursor()
        },
        {
          ...config,
          target: hoverRef,
        }
      )
    }

    return [hoverRef, hovering]
  }

  const useGesture = createUseGesture([
    moveAction,
    hoverAction,
    scrollAction,
    dragAction,
  ])

  const CursorProvider: FC<PropsWithChildren> = props => {
    // Cursor props
    const [position, setPosition] = useState<[number, number]>([0, 0])
    const [angle, setAngle] = useState(0)

    // Cursor custom props
    const [options, setOptions] = useState<A | null>(null)

    // Actions
    const enableCursor = useCallback((o: A) => setOptions(o), [setOptions])
    const disableCursor = useCallback(() => setOptions(null), [setOptions])

    // Gesture
    if (isMouseSupport) {
      useGesture(
        {
          onMouseMove: state =>
            setPosition(prevState => {
              const dx = prevState[0] - state.event.clientX
              const dy = prevState[1] - state.event.clientY

              setAngle(Math.atan2(dx, dy))

              return [state.event.clientX, state.event.clientY]
            }),
          // onDrag: state =>
          //   setPosition(prevState => {
          //     const dx = prevState[0] - state.xy[0]
          //     const dy = prevState[1] - state.xy[1]
          //
          //     setAngle(Math.atan2(dx, dy))
          //
          //     return state.xy
          //   }),
          onScrollStart: () => disableCursor(),
        },
        {
          target: typeof window !== "undefined" ? window : undefined,
          move: {
            mouseOnly: true,
          },
          // drag: {
          //   pointer: {
          //     touch: true,
          //   },
          // },
        }
      )
    }

    return (
      <CursorContext.Provider
        value={{
          position,
          angle,
          options,
          enableCursor,
          disableCursor,
        }}
      >
        {props.children}
      </CursorContext.Provider>
    )
  }

  return [useCursor, CursorProvider, useCursorHover] as const
}

export default createCursorContext
