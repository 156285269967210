import React, { FunctionComponent } from "react"
import { PageProps } from "gatsby"
import { CursorProvider } from "./components/Cursor"
import { LoadingProvider } from "./components/Loading"
import createLazyContext from "./contexts/createLazyContext"

// Context
export const [useLibsContext, LibsProvider] = createLazyContext<{
  anime: any
  fontfaceobserver: any
}>()

// Componenet
interface OwnProps {}

type Props = PageProps<OwnProps>

const WrapRoot: FunctionComponent<Props> = props => {
  return (
    <LibsProvider>
      <CursorProvider>
        <LoadingProvider loading={true}>{props.children}</LoadingProvider>
      </CursorProvider>
    </LibsProvider>
  )
}

export default WrapRoot
